export function attachGamepadInput() {
    var gamepadAPI = {
        controller: {},
        turbo: false,
        connect: function () { },
        disconnect: function () { },
        update: function () { },
        buttonPressed: function () { },
        buttons: [],
        buttonsCache: [],
        buttonsStatus: [],
        axesStatus: []
    };
    window.addEventListener("gamepadconnected", function (e) {
        console.log("Gamepad connected at index %d: %s. %d buttons, %d axes.",
            e.gamepad.index, e.gamepad.id,
            e.gamepad.buttons.length, e.gamepad.axes.length);
    });

    window.addEventListener("gamepaddisconnected", function (e) {
        console.log("Gamepad disconnected from index %d: %s",
            e.gamepad.index, e.gamepad.id);
    });

    var gamepads = {};

    function gamepadHandler(event, connecting) {
        var gamepad = event.gamepad;
        // Note:
        // gamepad === navigator.getGamepads()[gamepad.index]

        if (connecting) {
            gamepads[gamepad.index] = gamepad;
        } else {
            delete gamepads[gamepad.index];
        }
    }



};